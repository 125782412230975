
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { Location } from "vue-router";

  @Component
  export default class GeneralNavLink extends Vue {
    @Prop() active!: boolean;
    @Prop() to?: Location;
    @Prop() href?: string;
  }
